import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import Theme from '../../Theme';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';

const pageSize = 20;

function ProfileEventRegistration(props) {
  const [user] = useOutlet('user');
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const resp = await AppActions.twpaaFetchEventRegistrationListByUser({
        paging,
      });
      resp.results = resp.results.map((it) => {
        return {
          ...it,
          product: it.product[0],
        };
      });
      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [paging]);

  React.useEffect(() => {
    fetchDocumentList();
  }, [fetchDocumentList]);

  return (
    <Wrapper>
      <h1 style={{fontSize: 28}}>活動報名紀錄</h1>

      <Ant.Table
        columns={[
          {
            title: '活動',
            key: 'event_id',
            render: (_, record) => {
              return <div>{record.product?.name}</div>;
            },
          },
          {
            title: '報名日期',
            key: 'title',
            render: (_, record) => {
              return (
                <div>
                  <div>{record.date}</div>
                </div>
              );
            },
          },
          {
            title: '狀態',
            key: 'status',
            render: (_, record) => {
              const StatusDisplay = {
                success: '報名成功',
                failure: '取消報名',
              };
              return StatusDisplay[record.status] || '報名中';
            },
          },
        ]}
        dataSource={fetchResp?.results}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }
`;

export default withPageEntry(ProfileEventRegistration);
